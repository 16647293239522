import { BaseController } from "./base_controller";
import JaroWinkler from "jaro-winkler";

export default class extends BaseController {
  static targets = ["image", "name", "bio", "age", "common", "tag", "pronouns"];
  static values = {
    peerId: String,
    image: String,
    name: String,
    bio: String,
    age: Number,
    common: Array,
    pronouns: Array,
  };

  connect() {
    if (!window.location.hash) return;

    this.peerIdValue = window.location.hash.slice(1);
    this.sluttyProfile = JSON.parse(window.localStorage.sluttyProfile || "{}");
  }

  peerIdValueChanged(peerIdValue, oldPeerIdValue) {
    if (!peerIdValue) return;
    if (peerIdValue === oldPeerIdValue) return;

    const remoteProfile = window.localStorage[peerIdValue];

    if (!remoteProfile) return;

    this.remoteProfile = JSON.parse(remoteProfile);
    this.remoteProfile.common = this.remoteProfile.include.filter(x => {
      return this.sluttyProfile.include.includes(x) || this.sluttyProfile.include.some(y => JaroWinkler(x, y) > 0.9);
    });
    const keys = Object.keys(this.constructor.values);

    for (const field in this.remoteProfile) {
      if (!keys.includes(field)) continue;

      this[`${field}Value`] = this.remoteProfile[field];
    }
  }

  imageValueChanged(imageValue) {
    for (const imageTarget of this.imageTargets) {
      imageTarget.src = imageValue;
    }
  }

  nameValueChanged(nameValue) {
    for (const nameTarget of this.nameTargets) {
      nameTarget.innerText = nameValue;
    }
  }

  bioValueChanged(bioValue) {
    for (const bioTarget of this.bioTargets) {
      bioTarget.innerText = bioValue;
    }
  }

  ageValueChanged(ageValue) {
    for (const ageTarget of this.ageTargets) {
      ageTarget.innerText = ageValue;
    }
  }

  pronounsValueChanged(pronounsValue) {
    pronounsValue = pronounsValue.join("/");

    for (const pronounsTarget of this.pronounsTargets) {
      pronounsTarget.innerText = pronounsValue;
    }
  }

  commonValueChanged(commonValue, oldCommonValue = []) {
    const valuesToRemove = oldCommonValue.filter(x => !commonValue.includes(x));
    const valuesToAdd = commonValue.filter(x => !oldCommonValue.includes(x));

    for (const commonTarget of this.commonTargets) {
      for (const value of valuesToAdd) {
        commonTarget.appendChild(this.applyTemplate({ value }, this.tagTarget));
      }

      for (const value of valuesToRemove) {
        commonTarget.querySelector(`[data-tag="${value}"]`)?.remove();
      }
    }
  }
}
