import { Controller } from "@suttyweb/stimulus";

export default class extends Controller {
  static targets = ["image", "input"];
  static values = {
    data: String,
  };

  dataValueChanged(dataValue) {
    if (!dataValue) return;

    this.imageTarget.src = dataValue;
  }

  preview(event) {
    this.imageTarget.src = window.URL.createObjectURL(this.inputTarget.files[0]);
  }
}
