import { BaseController } from "./base_controller";
import JaroWinkler from "jaro-winkler";

export default class extends BaseController {
  static targets = ["profiles", "profile", "profileTemplate"];
  static values = {
    remoteProfileUrl: String,
  };

  connect() {
    this.profiles = {};
    this.sluttyProfile = JSON.parse(window.localStorage.sluttyProfile || "{}");
  }

  newProfile({ detail: profile }) {
    const peerId = profile.peerId;
    const previousProfile = this.profiles[peerId];

    if (!this.matches(profile)) {
      if (previousProfile) previousProfile.remove();
      window.localStorage.removeItem(peerId);

      return;
    }

    if (previousProfile) {
      if (previousProfile.dataset.version == profile.version) return;

      previousProfile.remove();
    }

    profile.remoteProfile = `${this.remoteProfileUrlValue}#${peerId}`;

    window.localStorage[peerId] = JSON.stringify(profile);

    const profileTarget = this.applyTemplate(profile, this.profileTemplateTarget);

    this.profilesTarget.appendChild(profileTarget);
    this.profiles[peerId] = this.profilesTarget.lastElementChild;
  }

  matches(profile) {
    const theyAreNotAMatchForMe = profile.include.some(x => this.includesSimilar(x, this.sluttyProfile.exclude));
    const iAmNotAMatchForThem = this.sluttyProfile.include.some(x => this.includesSimilar(x, profile.exclude));

    return !theyAreNotAMatchForMe && !iAmNotAMatchForThem;
  }

  includesSimilar(needle, stack, similarity = 0.9) {
    return stack.includes(needle) || stack.some(x => JaroWinkler(needle, x) > similarity);
  }
}
