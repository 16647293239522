import { BaseController } from "./base_controller";

export default class extends BaseController {
  static targets = ["value", "valueTemplate", "values", "datalist", "optionTemplate"];
  static values = {
    name: String,
    values: Array,
  };

  connect() {
    if (!this.hasDatalistTarget || !this.hasOptionTemplateTarget) return;

    const storedValues = window.localStorage[this.nameValue];

    if (!storedValues) return;

    for (const value of JSON.parse(storedValues)) {
      const data = { value };
      this.datalistTarget.appendChild(this.applyTemplate(data, this.optionTemplateTarget));
    }
  }

  valuesValueChanged(valuesValue, oldValuesValue) {
    const valuesToRemove = oldValuesValue.filter(x => !valuesValue.includes(x));
    const valuesToAdd = valuesValue.filter(x => !oldValuesValue.includes(x));

    for (const valueToAdd of valuesToAdd) {
      this.add(valueToAdd);
    }
  }

  enter(event) {
    switch (event.keyCode) {
      case 13:
      case 32:
        break;
      default:
        return
    }

    this.input();
  }

  add(value) {
    const data = {
      name: `${this.nameValue}[]`,
      value,
      id: `${this.nameValue}_${value}`,
    };

    const inputTarget = this.applyTemplate(data, this.valueTemplateTarget);

    this.valuesTarget.appendChild(inputTarget);
  }

  input(event) {
    event?.preventDefault();
    const value = this.valueTarget.value.trim();

    if (!value) return;
    if (this.valuesValue.includes(value)) return;

    this.valuesValue = [...this.valuesValue, value];
    this.valueTarget.value = "";
  }
}
