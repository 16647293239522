import { BaseController } from "./base_controller";
import { createLibp2p } from "libp2p";
import { WebSockets } from "@libp2p/websockets";
import { WebRTCStar } from "@libp2p/webrtc-star";
import { Noise } from "@chainsafe/libp2p-noise";
import { Mplex } from "@libp2p/mplex";
import { GossipSub } from "@chainsafe/libp2p-gossipsub";
import { fromString } from "uint8arrays/from-string";
import { toString } from "uint8arrays/to-string";
import { peerIdFromKeys } from "@libp2p/peer-id";

export default class extends BaseController {
  static targets = ["peers", "log", "discoveredPeers"];
  static values = {
    topic: {
      type: String,
      default: "slutty",
    },
    peers: {
      type: Number,
      default: 0,
    },
    discoveredPeers: {
      type: Number,
      default: 0,
    },
    rendezVous: {
      type: Array,
      default: [
        "/dns4/slutty.sutty.local/tcp/4000/wss/p2p-webrtc-star",
      ],
    },
    pronouns: Array,
    tags: Array,
  };

  pronounsValueChanged(pronounsValue) {
    if (pronounsValue.length === 0) return;

    window.localStorage.pronouns = JSON.stringify(pronounsValue);
  }

  tagsValueChanged(tagsValue) {
    if (tagsValue.length === 0) return;

    window.localStorage.include = JSON.stringify(tagsValue);
    window.localStorage.exclude = JSON.stringify(tagsValue);
  }

  peersValueChanged(peersValue, oldPeersValue) {
    for (const peersTarget of this.peersTargets) {
      peersTarget.innerText = peersValue;
    }
  }

  discoveredPeersValueChanged(discoveredPeersValue, oldPeersValue) {
    for (const peersTarget of this.discoveredPeersTargets) {
      peersTarget.innerText = discoveredPeersValue;
    }
  }

  get webRTCStar() {
    if (!this._webRTCStar) this._webRTCStar = new WebRTCStar();

    return this._webRTCStar;
  }

  async configure() {
    let peerId;
    const privateKey = window.localStorage.privateKey;
    const publicKey = window.localStorage.publicKey;
    if (privateKey && publicKey) {
      this.appendToLog("Recovering peerId");
      peerId = await peerIdFromKeys(
        new Uint8Array(JSON.parse(publicKey)),
        new Uint8Array(JSON.parse(privateKey))
      );
    }

    this.libp2p = await createLibp2p({
      peerId,
      addresses: {
        listen: this.rendezVousValue,
      },
      transports: [
        new WebSockets(),
        this.webRTCStar,
      ],
      connectionEncryption: [
        new Noise(),
      ],
      streamMuxers: [
        new Mplex(),
      ],
      peerDiscovery: [
        this.webRTCStar.discovery,
      ],
      pubsub: new GossipSub(),
    });

    window.libp2p = this.libp2p;
    window.localStorage.privateKey = JSON.stringify(Array.from(this.libp2p.peerId.privateKey));
    window.localStorage.publicKey = JSON.stringify(Array.from(this.libp2p.peerId.publicKey));

    return this.libp2p;
  }

  async connect() {
    await this.configure();

    this.peerIds = new Set();
    this.discoveredPeerIds = new Set();

    this.peerDiscovery = this.peerDiscovery.bind(this);
    this.peerConnect = this.peerConnect.bind(this);
    this.peerDisconnect = this.peerDisconnect.bind(this);
    this.messageReception = this.messageReception.bind(this);

    this.libp2p.addEventListener("peer:discovery", this.peerDiscovery);
    this.libp2p.connectionManager.addEventListener("peer:connect", this.peerConnect);
    this.libp2p.connectionManager.addEventListener("peer:disconnect", this.peerDisconnect);

    await this.libp2p.start();

    this.peerId = this.libp2p.peerId.toString();

    window.localStorage.peerId = this.peerId;

    this.appendToLog(`Connected as ${this.peerId}`);

    await this.libp2p.pubsub.subscribe(this.topicValue);
    this.libp2p.pubsub.addEventListener("message", this.messageReception);
    this.appendToLog(`Subscribed to ${this.topicValue}`);

    setInterval(async () => {
      const profile = window.localStorage.sluttyProfile;

      if (!profile) return;

      const parsedProfile = JSON.parse(profile);
      parsedProfile.peerId = this.peerId;

      this.appendToLog("Distributing our profile...");

      try {
        await this.libp2p.pubsub.publish(this.topicValue, fromString(JSON.stringify(parsedProfile)));
      } catch(e) {
        console.log(e);
      }
    }, 5000);
  }

  async peerDiscovery(event) {
    const peer = event.detail;
    const peerId = peer.id.toString();

    this.appendToLog(`Peer discovered: ${peerId}`);

    if (![...this.discoveredPeerIds].includes(peerId)) {
      this.discoveredPeersValue = this.discoveredPeersValue + 1;
      this.discoveredPeerIds.add(peerId);
    }

    this.libp2p.peerStore.addressBook.set(peer.id, peer.multiaddrs);

    try {
      await this.libp2p.dial(peer.id);
    } catch(e) {
      this.appendToLog(`Couldn't connect to: ${peerId}`);
    }
  }

  peerConnect(event) {
    const connection = event.detail;
    const peer = connection.remotePeer;
    const peerId = peer.string;

    this.peersValue = this.peersValue + 1;
    this.appendToLog(`Peer joined: ${peerId}`);
  }

  peerDisconnect(event) {
    const connection = event.detail;
    const peer = connection.remotePeer;
    const peerId = peer.string;

    this.discoveredPeersValue = this.discoveredPeersValue - 1;
    this.discoveredPeerIds.delete(peerId);

    this.peersValue = this.peersValue - 1;
    this.appendToLog(`Peer left: ${peerId}`);
  }

  appendToLog(message) {
    console.log(message);
  }

  messageReception(event) {
    const peer = event.detail.from;
    const peerId = peer.toString();
    const message = JSON.parse(toString(event.detail.data));

    if (message.peerId !== peerId) return;

    const profile = message;

    let tags = [];

    if (profile.exclude) tags = [...tags, ...profile.exclude];
    if (profile.include) tags = [...tags, ...profile.include];

    this.tagsValue = this.unique(tags);

    if (profile.pronouns) this.pronounsValue = this.unique([...this.pronounsValue, ...profile.pronouns]);

    this.dispatchToWindow("profile", profile);
  }

  unique(array) {
    return array.filter((x, i, a) => a.indexOf(x) === i);
  }
}
