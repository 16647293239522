// Example: Import a stylesheet in <sourceCodeDir>/index.css
// import '~/index.css'
import "regenerator-runtime/runtime";

// Turbo acelera la navegación al no tener que recargar todo el JS y CSS
// de la página, con lo que se siente más rápida y "nativa".
//
// Cambiamos de turbolinks a turbo porque turbo soporta la función
// fetch(), que luego es interceptada por el SW para obtener las
// direcciones localmente.
import * as Turbo from "@hotwired/turbo";
Turbo.start();

import { Application } from "@suttyweb/stimulus";
import { registerControllers } from "stimulus-vite-helpers";

const application = Application.start();
const controllers = import.meta.globEager("../controllers/*_controller.js");
registerControllers(application, controllers);
