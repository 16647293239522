import { BaseController } from "./base_controller";

export default class extends BaseController {
  static targets = ["form"];
  static values = {
    floatingAlert: {
      type: String,
      default: "Saved!",
    },
  };

  connect() {
    this.storedProfile = window.localStorage.sluttyProfile;

    if (!this.storedProfile) return;

    this.sluttyProfile = JSON.parse(this.storedProfile);

    for (const field in this.sluttyProfile) {
      if (field == "version") continue;
      if (field == "peerId") continue;

      const inputGroup = this.formTarget.querySelector(`[data-name="${field}"]`);

      if (!inputGroup) {
        console.error("Field missing", field);
        continue;
      }

      let value = this.sluttyProfile[field];

      switch (inputGroup.dataset.type) {
        case "array":
          inputGroup.dataset.arrayValuesValue = JSON.stringify(value);
          break;
        case "image":
          inputGroup.dataset.imagePreviewDataValue = value;
          break;
        default:
          const input = inputGroup.querySelector("input,textarea");
          if (!input) continue;

          input.value = value;
      }
    }
  }

  async save(event) {
    event?.preventDefault();

    const formData = new FormData(event.target);
    const arrayEntries = {};
    const sluttyProfile = {
      version: `${(new Date).getTime()}`,
      peerId: window.localStorage.peerId,
    };

    for (const entry of formData.entries()) {
      let key = entry[0];
      let value = entry[1];

      if (key.endsWith("[]")) {
        key = key.slice(0, key.length - 2);

        if (!arrayEntries[key]) arrayEntries[key] = [];

        arrayEntries[key].push(value);
        continue;
      }

      switch (value.constructor.name) {
        case "File":
          const inputGroup = this.formTarget.querySelector(`[data-name="${key}"]`);
          const file = inputGroup.querySelector(`input[type="file"]`).files[0];

          if (file) {
            value = await this.toBase64(file);
          } else {
            value = inputGroup.dataset.imagePreviewDataValue;
          }
        default:
          sluttyProfile[key] = value;
      }
    }

    this.sluttyProfile = {...this.sluttyProfile, ...sluttyProfile, ...arrayEntries};

    window.localStorage.sluttyProfile = JSON.stringify(this.sluttyProfile);

    const content = this.floatingAlertValue;

    this.dispatchToWindow("floatingAlert", { content });
  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = () => resolve(fileReader.result);
      fileReader.onerror = (error) => reject(error);

      fileReader.readAsDataURL(file);
    });
  }
}
